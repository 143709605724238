import formatUnixTimestamp from "../utils/formatedUnix";
import {Link} from "react-router-dom";
import {getUser} from "../api/respons";

export const columnYookassaOrder = [
    {
        title: "id",
        dataIndex: "id",
        key: "id",
        type: "text",
        editable: false,
        sorter: (a, b) => a.id - b.id,
        render: (text, record) => (
            <Link className="underline text-sky-500" to={`/order/${record._id}`}>
                {text}
            </Link>
        )
    },
    {
        title: "Статус",
        dataIndex: "status",
        key: "status",
        type: "text",
        editable: false,
        sorter: (a, b) => a.status - b.status,
    },
    {
        title: "Сумма",
        dataIndex: "amount",
        key: "amount",
        type: "number",
        editable: false,
        sorter: (a, b) => a.amount.value - b.amount.value,
        render: (text, record) => (
            record.amount.value + " " +  record.amount.currency
        )
    },
    {
        title: "Метод платежа",
        dataIndex: "payment_method.type",
        key: "payment_method.type",
        type: "text",
        editable: false,
        sorter: (a, b) => a.payment_method.type - b.payment_method.type,
        render: (text, record) => (
            record.payment_method.type
        )
    },
    {
        title: "Пользователь",
        dataIndex: "metadata.user",
        key: "metadata.user",
        type: "text",
        editable: false,
        sorter: (a, b) => a.metadata.user - b.metadata.user,
        render:  (text, record) => (
            record.metadata.user
        )
    },
    {
        title: "Внутренний ID",
        dataIndex: "metadata.order",
        key: "metadata.order",
        type: "text",
        editable: false,
        sorter: (a, b) => a.metadata.order - b.metadata.order,
        render:  (text, record) => (
            record.metadata.order
        )
    },
    {
        title: "Дата",
        dataIndex: "created_at",
        key: "created_at",
        type: "text",
        editable: false,
        sorter: (a, b) => a.created_at - b.created_at,
        render: (text, record) => {
            const unixTime = Math.floor(new Date(record.created_at).getTime() / 1000);
            return formatUnixTimestamp(unixTime)
        }
    }
];