const definedTextResponse = (text) => {
    switch (text) {
        case "INCORRECT_LOGIN_OR_PASSWORD":
            return "Неверный логин или пароль";
            break;
        case "NOT_LOGIN_OR_PASSWORD":
            return "Лоин или пароль не введены";
            break;
        case "NOT_ENOUGH_RIGHTS":
            return "Пользователь не является администратором";
            break;
        case "NO_DELETE_OPTION":
            return "Неверные или отсутсвуют параметры для удаления пользователя";
            break;
        case "USER_NOT_FOUND":
            return "Пользователь не найден";
            break;
        case "USER_NOT_FOUND":
            return "Пользователь не найден";
            break;
        case "REQUIRED_DATA_MISSING":
            return "Необходимые данные для операции отсутсвуют или неверны";
            break;
        case "INVALID_NAME_FORMAT":
            return "Неправильный формат для имени";
            break;
        case "SERVER_NOT_EXISTS":
            return "ID сервера не соотвествует имени в базе";
            break;
        case "INVALID_IPV4_FORMAT":
            return "Формат IPV4 неверный";
            break;
        case "INVALID_REGION_FORMAT":
            return "Формат региона неверный";
            break;
        case "INVALID_REGION_FORMAT":
            return "Формат пропускной способности неверный";
            break;
        case "INVALID_AVAILABLE_FORMAT":
            return "Формат кол-ва доступных серверов неверный";
            break;
        case "INVALID_API_KEY_FORMAT":
            return "Формат api key неверный";
            break;
        case "SERVER_NOT_FOUND":
            return "Сервер не найден";
            break;
        case "INVALID_USERNAME_FORMAT":
            return "Формат username неверный";
            break;
        case "USER_NOT_EXISTS":
            return "ID пользователя не соотвествует имени в базе";
            break;
        case "INVALID_BALANCE_FORMAT":
            return "Формат баланса неверный";
            break;
        case "KEY_NOT_FOUND":
            return "Ключ не найден";
            break;
        case "KEYS_NOT_FOUND":
            return "Ключи не найдены";
            break;
        case "SERVERS_NOT_FOUND":
            return "Серверы не найдены";
            break;
        case "USERS_NOT_FOUND":
            return "Пользователи не найдены";
            break;
        case "NO_USERS_TO_POST":
            return "Нет пользователей для отправки сообщения";
            break;
        case "SERVER_ALREADY_EXISTS":
            return "Севрер с именем уже существует";
            break;
        case "SERVER_IS_ALREADY_INITIALIZED":
            return "Севрер уже инициализирован";
            break;
        case "KEY_STATUS_NOT_VALID":
            return "Статус ключа неверный";
            break;
        case "KEY_STATUS_NOT_VALID":
            return "Статус ключа неверный";
            break;
        case "SERVER_NOT_AVAILABLE":
            return "Нет доступных серверов";
            break;
        case "AUTHORIZATION_REQUIRED":
            return "Необходимо авторизироваться";
            break;
        case "INVALID_TOKEN":
            return "Токен неверный";
            break;
        case "OK":
            return "Успешно";
            break;
        case "DOMAIN_IS_ALREADY":
            return "Доменное имя уже присутствует в списке";
            break;
        default:
            return "Неизвестная ошибка";
            break;
    }
}

export default definedTextResponse;