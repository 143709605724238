import {getConfig, editConfig} from "../api/respons";
import {useEffect, useState} from "react";
import MessageModal from "./MessageModal";
import {configColumns} from "../config/column";
import GenericForm from "./GenericForm";

const Config = () => {
    const [config, setConfig] = useState(null);
    const [message, setMessage] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const data = await getConfig();
                if (data.config) {
                    setConfig(data.config);
                    console.log(data.config);
                }
            } catch (error) {
                setMessage(error);
            } finally {
                setLoading(false);
            }
        };

        fetchConfig();
    }, []);
    const handleSubmit = async (values) => {
        try {
            await editConfig(values)
            setMessage({code: 200, text: "Редактирование конфигурации прошло успешно"})
        } catch (error) {
            setMessage(error);
        }

        console.log(values)
    }
 return (<section className="h-full flex flex-col justify-center items-center relative">
     <h1 className="text-center py-5 text-3xl">Конфигурация системы</h1>
     <div className="min-w-96  w-1/3 flex flex-row justify-between">
         {config ?
             <GenericForm
                 columns={configColumns}
                 data={config}
                 onSubmit={handleSubmit}
                 onChange={(key, value) => setConfig({...config, [key]: value})}/>
             : null}
     </div>
         <MessageModal message={message}/>
 </section>
)
}

export default Config