import { Form, Input } from "antd";

const ExtendKey = ({ form }) => {
  return (
    <Form form={form} layout="vertical" name="releaseKeyForm">
      <Form.Item
        name="inputValue"
        label="Введите, на какой период выпустить ключ (в часах):"
        rules={[
          { required: true, message: "Пожалуйста, введите значение." },
          {
            validator: (_, value) => {
              if (value < 0) {
                return Promise.reject(
                  new Error("Значение должно быть не меньше 0.")
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input type="number" min={0} placeholder="Введите число" />
      </Form.Item>
    </Form>
  );
};

export default ExtendKey;
