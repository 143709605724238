//const serverURL = "http://localhost:4000/";
const serverURL = "https://api.smatronline.tw1.su/";

export const login = async (username, password) => {
  try {
    const response = await fetch(`${serverURL}login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const getServers = async () => {
  try {
    const response = await fetch(`${serverURL}server/get_servers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const getServer = async (_id) => {
  try {
    const response = await fetch(`${serverURL}server/get_server/${_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const getUser = async (_id) => {
  try {
    const response = await fetch(`${serverURL}user/get_user/${_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const getUsers = async () => {
  try {
    const response = await fetch(`${serverURL}user/get_users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const getKeys = async () => {
  try {
    const response = await fetch(`${serverURL}key/get_keys`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const getKey = async (_id) => {
  try {
    const response = await fetch(`${serverURL}key/get_key/${_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const newServer = async (server) => {
  try {
    const response = await fetch(`${serverURL}new_server`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(server),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const editServer = async (server) => {
  try {
    const response = await fetch(`${serverURL}server/edit_server`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(server),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return {data};
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const editUser = async (user) => {
  try {
    const response = await fetch(`${serverURL}user/edit_user`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await fetch(`${serverURL}user/delete_user/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const massSendMessage = async (message) => {
  try {
    const response = await fetch(`${serverURL}mass_send_message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const recallKey = async (_id) => {
  try {
    const response = await fetch(`${serverURL}key/recall_key`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};


export const extendKey = async (_id, hours) => {
  try {
    const response = await fetch(`${serverURL}key/extend_key`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id, hours }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const releasePersonKey = async (_id, hours, serverName) => {
  try {
    const response = await fetch(`${serverURL}user/release_person_key`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id, hours, serverName }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const getOrdersNowpayments = async () => {
  try {
    const response = await fetch(`${serverURL}order/nowpayments`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const accessUser = async (_id) => {
  try {
    const response = await fetch(`${serverURL}user/access_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const openVpnServerReboot = async (_id, ip) => {
  try {
    const response = await fetch(`${serverURL}server/reboot_vpn_server`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id, ip }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const openVpnServerStop = async (_id, ip) => {
  try {
    const response = await fetch(`${serverURL}server/stop_vpn_server`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id, ip }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const openVpnServerStart = async (_id, ip) => {
  try {
    const response = await fetch(`${serverURL}server/start_vpn_server`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id, ip }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const rebootServer = async (_id) => {
  try {
    const response = await fetch(`${serverURL}server/reboot/${_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const downSpeed = async (_id) => {
  try {
    const response = await fetch(`${serverURL}key/down_speed`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const upSpeed = async (_id) => {
  try {
    const response = await fetch(`${serverURL}key/up_speed`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const getToken = async () => {
  try {
    const response = await fetch(`${serverURL}server/get_temporary_token`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const getConfig = async () => {
  try {
    const response = await fetch(`${serverURL}config/get_config`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const getPayments= async () => {
  try {
    const response = await fetch(`${serverURL}payment/get_payment`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const editConfig = async (config) => {
  try {
    const response = await fetch(`${serverURL}config/edit_config`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({config}),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return {data};
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const getOrdersYookassa = async () => {
  try {
    const response = await fetch(`${serverURL}order/yookassa`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const getCountServers = async () => {
  try {
    const response = await fetch(`${serverURL}server/count`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const updatesServers = async (file) => {
  try {

    const formData = new FormData();
    formData.append("file", file); // "file" должно соответствовать имени, ожидаемому сервером

    const response = await fetch(`${serverURL}server/all_updates`, {
      method: "POST",
      headers: {
        // Убираем Content-Type, т.к. браузер сам установит его при использовании FormData
      },
      credentials: "include",
      body: formData,
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
}

export const domainCheck = async (domain) => {
  try {
    const response = await fetch(`${serverURL}server/domain_check`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ domain }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const domainAdd = async (domain) => {
  try {
    const response = await fetch(`${serverURL}server/domain_add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ domain }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};

export const domainRem = async (domain) => {
  try {
    const response = await fetch(`${serverURL}server/domain_rem`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ domain }),
      credentials: "include",
    });

    if (!response.ok) {
      return response.text().then((text) => {
        const textString = JSON.parse(text);
        throw { code: response.status, text: textString.message };
      });
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error; // Вы можете обработать ошибку в компоненте, который вызывает эту функцию
  }
};
