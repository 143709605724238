import { serverColumns } from "../config/column";
import { getServers } from "../api/respons";
import { useEffect, useState } from "react";
import TableCustom from "./TableCustom";
import MessageModal from "./MessageModal";

const Servers = () => {
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  useEffect(() => {
    const fetchServers = async () => {
      try {
        const data = await getServers();
        if (data.servers && data.servers.length > 0) {
          setServers(data.servers);
        }
        //setError({code: 200, message: "OK", type: "error"});
      } catch (error) {
        console.error("Ошибка при получении серверов:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchServers();
  }, []);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="container mx-auto p-4 te">
      <h1 className="text-center py-5 text-3xl">Список серверов</h1>
      <TableCustom items={servers} columns={serverColumns} />
      <MessageModal message={error}></MessageModal>
    </div>
  );
};

export default Servers;
