import { useEffect, useState } from "react";
import { login } from "../api/respons";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [error, setError] = useState("");
  const [valueLogin, setValueLogin] = useState("");
  const [valuePass, setValuePass] = useState("");
  const navigate = useNavigate();

  const handleValueLogin = (e) => {
    setValueLogin(e.target.value);
  };

  const handleValuePassword = (e) => {
    setValuePass(e.target.value);
  };

  const fetchLogin = async (e, valueLogin, valuePass) => {
    e.preventDefault();
    try {
      await login(valueLogin, valuePass);
      await navigate("/");
    } catch (error) {
      console.error("Ошибка при получении серверов:", error);
      if (error.text) {
        setError(error.text);
      }
    }
  };

  return (
    <section className="flex w-screen h-screen justify-center items-center bg-slate-200">
      <form
        className="flex flex-col w-96 gap-4 bg-white p-5 shadow-md rounded-md"
        onSubmit={(e) => fetchLogin(e, valueLogin, valuePass)}
      >
        <h1 className="text-center text-3xl mb-4 font-normal">Авторизация</h1>
        <input
          className="bg-n border-b border-gray-300 rounded-md"
          type="text"
          placeholder="Login"
          onChange={(e) => handleValueLogin(e)}
          value={valueLogin}
        />
        <input
          className="bg-n border-b border-gray-300 rounded-md"
          type="password"
          placeholder="Password"
          onChange={(e) => handleValuePassword(e)}
          value={valuePass}
        />
        <span className="text-sm text-center text-red-500 h-5">{error}</span>
        <button
          className="h-10 bg-slate-400 text-white rounded-md"
          type="submit"
        >
          Войти
        </button>
      </form>
    </section>
  );
};

export default Login;
