import Header from "./components/Header";
import Servers from "./components/Servers";
import Server from "./components/Server";
import Login from "./components/Login";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Users from "./components/Users";
import Keys from "./components/Keys";
import OrdersNowpayments from "./components/OrdersNowpayments";
import User from "./components/User";
import Payments from "./components/Payments";
import Config from "./components/Config";
import Key from "./components/Key";
import Token from "./components/Token";
import OrdersYookassa from "./components/OrdersYookassa";
import Updates from "./components/Updates";
import DomainControl from "./components/DomainControl";

function App() {
  const location = useLocation();
  return (
    <main className="App">
      {location.pathname !== "/login" && <Header />}
      <Routes>
        <Route path="/servers" element={<Servers />} />
        <Route path="/users" element={<Users />} />
        <Route path="/keys" element={<Keys />} />
        <Route path="/servers/:_id" element={<Server />} />
        <Route path="/users/:_id" element={<User />} />
        <Route path="/login" element={<Login />} />
        <Route path="/order/nowpayment" element={<OrdersNowpayments />} />
        <Route path="/order/yookassa" element={<OrdersYookassa />} />
        <Route path="/config" element={<Config />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/key/:_id" element={<Key />} />
        <Route path="/servers/get_token/" element={<Token />} />
        <Route path="/servers/updates/" element={<Updates />} />
        <Route path="/servers/domain_control/" element={<DomainControl />} />
      </Routes>
    </main>
  );
}

export default App;
