// GenericForm.js
import React, { useEffect } from "react";
import {Form, Input, Select, Button, message, InputNumber, Switch} from "antd";

const { Option } = Select;

function GenericForm({ columns, data, onChange, onSubmit }) {
  const [form] = Form.useForm();

  useEffect(() => {
    // Устанавливаем начальные значения формы при изменении data
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, form]);

  const handleFinish = (values) => {
    onSubmit(values);
  };

  const handleFinishFailed = (errorInfo) => {
    message.error("Пожалуйста, исправьте ошибки в форме.");
    console.log("Ошибки формы:", errorInfo);
  };

  // Функция для рендеринга различных типов полей с учетом валидации
  const renderFormItem = (column) => {
    const { type, validation, editable, dataIndex, options } = column;

    // Разбиваем dataIndex на массив, если используется точечная нотация
    const name = dataIndex.split(".");

    let inputComponent;

    switch (type) {
      case "text":
        inputComponent = <Input type="text" disabled={!editable} />;
        break;
      case "number":
        inputComponent = (
          <InputNumber className="w-full" type="number" disabled={!editable} />
        );
        break;

      case "boolean":
        inputComponent = (
            <Switch  type="number" disabled={!editable} />
        );
        break;
      case "array":
        inputComponent = (
            <Select mode="tags"  disabled={!editable} />
        );
        break;
      case "email":
        inputComponent = <Input type="email" disabled={!editable} />;
        break;
      case "select":
        // Находим элемент в columns, который хранит массив options
        const optionsColumn = columns.find(col => col.key === options);
        const optionsToSelect = optionsColumn ? optionsColumn.options : [];

        inputComponent = (
            <Select disabled={!editable}>
              {optionsToSelect.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
              ))}
            </Select>
        );
        break;

      case "textarea":
        inputComponent = <Input.TextArea rows={4} disabled={!editable} />;
        break;
      default:
        inputComponent = <Input disabled={!editable} />;
    }

    // Определение правил валидации
    const rules = [];
    if (validation) {
      if (validation.required) {
        rules.push({
          required: true,
          message: validation.message || `Пожалуйста, введите ${column.title}`,
        });
      }
      if (validation.pattern) {
        rules.push({
          pattern: validation.pattern,
          message:
            validation.messagePattern || `Неверный формат ${column.title}`,
        });
      }
      if (validation.min !== undefined) {
        rules.push({
          type: type === "number" ? "number" : "string",
          min: validation.min,
          message: `Минимальное значение ${validation.min}.`,
        });
      }
      if (validation.max !== undefined) {
        rules.push({
          type: type === "number" ? "number" : "string",
          max: validation.max,
          message: `Максимальное значение ${validation.max}.`,
        });
      }
      if (validation.customValidator) {
        rules.push({ validator: validation.customValidator });
      }
    }

    return (
      <Form.Item
        key={column.key}
        name={name}
        label={column.title}
        rules={rules}
      >
        {inputComponent}
      </Form.Item>
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={data}
      onFinish={handleFinish}
      onFinishFailed={handleFinishFailed}
      className="space-y-4 py-3 w-full"
    >
      {columns.map((column) => renderFormItem(column))}
      <Form.Item>
        <Button type="primary" htmlType="submit" className="w-full mt-4">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

export default GenericForm;
