import { useParams } from "react-router-dom";
import {getServer, openVpnServerStart} from "../api/respons";
import { useEffect, useState } from "react";
import { serverColumns } from "../config/column";
import GenericForm from "./GenericForm";
import { Button } from "antd";
import Popup from "./Popup";
import { editServer, openVpnServerReboot, rebootServer, openVpnServerStop } from "../api/respons";
import MessageModal from "./MessageModal";

const Server = () => {
  const [server, setServer] = useState({});
  const [loading, setLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [action, setAction] = useState(null);
  const [textPopup, setTextPopup] = useState("");
  const { _id } = useParams();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const fetchServer = async (_id) => {
      try {
        const data = await getServer(_id);
        if (data.server) {
          setServer(data.server);
        }
      } catch (error) {
        console.error("Ошибка при получении серверов:", error);
        setMessage(error);
      } finally {
        setLoading(false);
      }
    };

    fetchServer(_id);
  }, []);

  const handleSubmit = async (values) => {
    try {
      await editServer({ server: values });
      console.log("Редактирование сервера прошло успешно");
      setMessage({code: 200, text: "Редактирование сервера прошло успешно"});
    } catch (error) {
      setMessage(error);
      console.error("Ошибка редактирования сервера: ", error);
    }
  };

  const fetchRebootVPNServer = async () => {
    try {
      await openVpnServerReboot(server._id, server.ipv4);
      console.log(`VPN севрер ${server.name} перезагружен`);
      setMessage({code: 200, text: "OpenVPN перезагружен"});
    } catch (error) {
      console.error("Ошибка при перезагрузки VPN севера:", error);
      setMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRebootServer = async () => {
    try {
      await rebootServer(server._id);
      console.log(`Сервер ${server.name} перезагружен`);
      setMessage({code: 200, text: "Сервер перезагружен"});
    } catch (error) {
      console.error("Ошибка при перезагрузки севера:", error);
      setMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStopVPNServer = async () => {
    try {
      await openVpnServerStop(server._id, server.ipv4);
      console.log(`VPN север ${server.name} остановлен`);
      setMessage({code: 200, text: "VPN север остановлен"});
    } catch (error) {
      console.error("Ошибка при остановке VPN севера:", error);
      setMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStartVPNServer = async () => {
    try {
      await openVpnServerStart(server._id, server.ipv4);
      console.log(`VPN север ${server.name} остановлен`);
      setMessage({code: 200, text: "VPN север остановлен"});
    } catch (error) {
      console.error("Ошибка при остановке VPN севера:", error);
      setMessage(error);
    } finally {
      setLoading(false);
    }
  };



  const handleAction = (actionType) => {
    setAction(actionType);
    setOpenPopup(true);
    switch (actionType) {
      case "reboot": {
        setTextPopup("Вы уверены, что хотите перезагрузить сервер?");
        break;
      }
      case "restartVpn": {
        setTextPopup("Вы уверены, что хотите перезагрузить VPN сервер?");
        break;
      }
      case "stopVpn": {
        setTextPopup("Вы уверены, что хотите остановить VPN сервер?");
        break;
      }
      case "startVpn": {
        setTextPopup("Вы уверены, что хотите запустить VPN сервер?");
        break;
      }
      case "fileUpload": {
        setTextPopup("Отправить обновление на сервер?");
        break;
      }
      default:
        setTextPopup("Подтвердите действие!");
        break;
    }
  };
  const handleCancelReboot = () => {
    setOpenPopup(false);
  };

  const handleConfirmAction = async () => {
    if (action === "reboot") {
      fetchRebootServer()
      console.log("Перезагрузка сервера...");

    } else if (action === "restartVpn") {
      fetchRebootVPNServer();
      console.log("Перезагрузка VPN сервера...");

    } else if (action === "stopVpn") {
      fetchStopVPNServer()
      console.log("Остановка VPN сервера...");

    } else if (action === "startVpn") {
      fetchStartVPNServer()
      console.log("Запуск VPN сервера...");

    } else if (action === "fileUpload") {
      console.log("Отправка файла обновления на сервер");
      // Здесь вызов API для запуска VPN сервера
    }
    setOpenPopup(false);
  };

  return (
    <div className="h-full flex flex-col justify-center items-center relative">
      <MessageModal message={message}></MessageModal>
      <h1 className="text-center py-5 text-3xl">
        Параметры сервера {server.name}
      </h1>
      <div className="w-[680px] flex flex-row justify-between">
        <GenericForm
          columns={serverColumns}
          data={server}
          onChange={(key, value) => setServer({ ...server, [key]: value })}
          onSubmit={handleSubmit}
        />
        <div className="w-1/3 m-6">
          <Button
            onClick={() => handleAction("reboot")}
            type="primary"
            className="w-full mt-4"
          >
            Перезагрузить сервер
          </Button>
          <Button
            onClick={() => handleAction("restartVpn")}
            type="primary"
            className="w-full mt-4"
          >
            Перезагрузить VPN сервер
          </Button>
          <Button
            onClick={() => handleAction("stopVpn")}
            type="primary"
            className="w-full mt-4"
          >
            Остановить VPN сервер
          </Button>
          <Button
            onClick={() => handleAction("startVpn")}
            type="primary"
            className="w-full mt-4"
          >
            Запустить VPN сервер
          </Button>
          <Button
            onClick={() => handleAction("fileUpload")}
            type="primary"
            className="w-full mt-4"
          >
            Загрузить файл обновления
          </Button>
        </div>
      </div>
      <Popup
        visible={openPopup}
        onConfirm={handleConfirmAction}
        onCancel={handleCancelReboot}
        text={textPopup}
        action={action} // Передаем функцию для установки файла
      />
    </div>
  );
};

export default Server;
