import React from "react";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const FileUpload = ({ onFileUpload }) => {
  return (
    <Upload
      beforeUpload={(file) => {
        onFileUpload(file); // Сохраняем файл в состоянии родительского компонента
        return false; // Возвращаем false, чтобы не автоматически загружать
      }}
    >
      <Button icon={<UploadOutlined />}>Загрузить файл</Button>
    </Upload>
  );
};

export default FileUpload;
