import {useEffect, useState} from "react";
import {getPayments} from "../api/respons";
import TableCustom from "./TableCustom";
import {paymentColumn} from "../config/column";
import MessageModal from "./MessageModal";


const Payments = () => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPayments = async () => {
            try {
                const data = await getPayments();
                if (data.payments && data.payments.length > 0) {
                    setPayments(data.payments);
                }
            } catch (error) {
                console.error("Ошибка при получении платежей:", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchPayments();
    }, []);

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (error) {
        return <div>Произошла ошибка: {error.message}</div>;
    }

    return (
        <div className="container mx-auto p-4 te">
            <h1 className="text-center py-5 text-3xl">Список платежей</h1>
            <TableCustom items={payments} columns={paymentColumn} />
            <MessageModal message={error}></MessageModal>
        </div>
    );
}
export default Payments;