import React, { useState } from "react";
import { Modal, Form } from "antd";
import FileUpload from "./FileUpload";
import InputPeriod from "./InputPeriod";
import ExtendKey from "./ExtendKey";

const Popup = ({ visible, onConfirm, onCancel, text, action }) => {
  const [file, setFile] = useState(null);
  const [form] = Form.useForm(); // Инициализация формы

  const handleFileUpload = (uploadedFile) => {
    setFile(uploadedFile);
  };

  const handleConfirm = async () => {
    if (action === "fileUpload" && file) {
      if (file.type === "text/x-sh" ) {
        await onConfirm(file); // Передаем файл в функцию подтверждения
      } else {
        console.log("Validation file failed", file.type);
      }

    } else if (action === "releaseKey") {
      form
        .validateFields() // Валидация полей формы
        .then((values) => {
          onConfirm(values); // Передача значения в функцию подтверждения
          console.log(values);
        })
        .catch((info) => {
          console.log("Validation Failed:", info);
        });
    } else if (action === "extendKey") {
      form
        .validateFields()
        .then((values) => {
          onConfirm(values.inputValue);
          console.log(values);
        })
        .catch((info) => {
          console.log("Validation Failed:", info);
        });
    } else {
      await onConfirm();
    }
  };

  const forms = () => {
    if (action === "fileUpload") {
      return <FileUpload onFileUpload={handleFileUpload} />;
    } else if (action === "releaseKey") {
      return <InputPeriod form={form} />;
    } else if (action === "extendKey") {
      return <ExtendKey form={form} />;
    }
  };

  return (
    <Modal
      title="Подтверждение"
      open={visible}
      onOk={handleConfirm}
      onCancel={onCancel}
      okText="Да"
      cancelText="Нет"
    >
      <p>{text}</p>
      {forms()}
    </Modal>
  );
};

export default Popup;
