import React, { useEffect, useState } from 'react';
import definedTextResponse from "../utils/definedTextResponse";
import {useNavigate} from "react-router-dom";

const  MessageModal = ({ message }) => {
    const [messages, setMessages] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (message && ["INVALID_TOKEN", "AUTHORIZATION_REQUIRED"].includes(message.text)) {
            navigate("/login");
        }
    }, [message]);

    useEffect(() => {
        if (message && Object.keys(message).length) {
            const newMessage = { ...message, id: Date.now(), opacity: 1 };
            setMessages((prev) => [...prev, newMessage]);
            console.log(newMessage);
            setTimeout(() => {
                setMessages((prev) =>
                    prev.map((msg) => msg.id === newMessage.id ? { ...msg, opacity: 0 } : msg)
                );
            }, 7000);

            setTimeout(() => {
                setMessages((prev) => prev.filter((msg) => msg.id !== newMessage.id));
            }, 8000);
        }
    }, [message]);

    return (
        <section className={`absolute left-0 top-1/2 bottom-0 w-[360px] z-0 ${messages.length ? "flex" : "hidden"} flex-col-reverse p-3`}>
            {messages.map(({id, code, text, opacity}) => {
                const isSuccess = code === 200;
                const styleColor = isSuccess ? "border-green-500 bg-green-100" : "border-red-500 bg-red-100";
                const styleColorText = isSuccess ? "text-green-500" : "text-red-500";
                const title = isSuccess ? "Информация:" : "Ошибка:";
                const explanation = definedTextResponse(text);

                return (
                    <div
                        key={id}
                        className={`w-full min-h-[90px] border-solid border-2 ${styleColor} rounded p-1 relative transition-opacity duration-1000 mb-2`}
                        style={{opacity}}
                    >
                        <button
                            className="absolute right-0 top-0 w-6 h-6 text-xl"
                            onClick={() => setMessages((prev) => prev.filter((msg) => msg.id !== id))}
                        >
                            ×
                        </button>
                        <h2 className={`${styleColorText} text-base`}>
                            {title} {code} {code !== 200 ? text : "Успешно"}
                        </h2>
                        <p className={`${styleColorText} text-xs`}>
                            {code !== 200 ? explanation : text}
                        </p>
                    </div>
                );
            })}
        </section>

    );
};

export default MessageModal;