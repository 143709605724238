import {Button} from "antd";
import {useEffect, useState} from "react";
import Popup from "./Popup";
import {getCountServers, updatesServers} from "../api/respons";
import MessageModal from "./MessageModal";

const Updates = ({}) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState("fileUpload");
    const [textPopup, setTextPopup] = useState("");
    const [countServers, setCountServers] = useState(null);
    const [message, setMessage] = useState(null);
    const [ok, setOk] = useState(null);
    const [error, setError] = useState([]);
    const [resToServer, setResToServer] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const data = await getCountServers();
                setCountServers(data.count);
            } catch (error) {
                console.error("Ошибка при получении серверов:", error);
                setMessage(error);
            }
        };

        fetchCount();
    }, []);

    const handleConfirmAction = async (file) => {
        if (file) {
            console.log("Отправка файла обновления на сервера");
            setOpenPopup(false);
            setLoading(true);
            try {
               const data = await updatesServers(file);
               console.log(data)
              const success = data.result.filter(res => res.status === "ok");
               const failed = data.result.filter(res => res.status === "error")
                setOk(success.length)
                setError(failed.length)
                setResToServer(data.result)
                console.log(success)
            } catch (error) {
                setMessage(error)
            }
            finally {
                setLoading(false);
            }
        } else {
            console.error("Нет файла для отправки")
        }
    };

    const handleAction = () => {
        setOpenPopup(true);
        setTextPopup("Отправить обновление на сервер?");
    }

    const handleCancelPopup = () => {
        setOpenPopup(false);
    };

    return (<selec className="flex flex-col items-center">
        <h1 className="text-center py-5 text-3xl">
            Обновления
        </h1>
        <div className="w-1/3">
            <ul>
                <li>Всего серверов - {countServers}</li>
                <li>Успешно обновлены - {ok}</li>
                <li>Обновлены с ошибкой: - {error} </li>

            </ul>
            <ul className={`flex text-xs`}>
            {resToServer.map((mess, index) => (
                mess.status === "error" ? (
                    <li key={index} className='mt-1 text-red-500'>Сервер {mess.server},<br/> Ошибка: {mess.error.message}</li>
                ) : (<li key={index} className='mt-1 text-green-500'>Сервер {mess.server},<br/> Ответ: {mess.output.output}</li>)


            ))}
            </ul>
            <Button
                onClick={() => handleAction()}
                type="primary"
                className="w-full mt-4"
                loading={loading}
            >
                Загрузить файл обновления
            </Button>
        </div>
        <Popup
            visible={openPopup}
            onConfirm={handleConfirmAction}
            onCancel={handleCancelPopup}
            text={textPopup}
            action={action}
        />

        <MessageModal message={message}></MessageModal>
    </selec>)
}

export default Updates;