import { useEffect, useState } from "react";
import ModalNav from "./ModalNav";

const NavLink = ({ link }) => {
  const [modalState, setModalState] = useState(false);
  const handleMouseEnter = () => {
    setModalState(true);
  };

  const handleMouseLeave = () => {
    setModalState(false);
  };
  return (
    <a
      href="#"
      className="transition-all duration-300 font-medium text-[#7A8699] hover:text-black cursor-pointer relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {link.title}
      <ModalNav state={modalState} links={link.links} />
    </a>
  );
};
export default NavLink;
