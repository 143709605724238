export default function formatUnixTimestamp(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Преобразуем Unix в миллисекунды
  // Получаем день, месяц и год
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
  const year = date.getFullYear();

  // Получаем часы и минуты
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Форматируем строку даты и времени
  const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}`;

  return formattedDateTime;
}
