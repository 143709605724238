import { useParams } from "react-router-dom";
import { getUser, releasePersonKey } from "../api/respons";
import { useEffect, useState } from "react";
import { userColumns } from "../config/column";
import GenericForm from "./GenericForm";
import { Button } from "antd";
import Popup from "./Popup";
import { editUser, accessUser } from "../api/respons";
import MessageModal from "./MessageModal";

const User = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [action, setAction] = useState(null);
  const [textPopup, setTextPopup] = useState("");
  const [perodReleaseKey, setProdReleaseKey] = useState(null); // Состояние для файла
  const { _id } = useParams();

  useEffect(() => {
    const fetchUser = async (_id) => {
      try {
        const data = await getUser(_id);
        if (data.user) {
          setUser(data.user);
        }
      } catch (error) {
        setMessage(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser(_id);
  }, []);

  const handleSubmit = async (values) => {
    try {
      await editUser({ user: values });
      setMessage({code: 200, text: "Данные пользователя отредактированы"});
    } catch (error) {
      setMessage(error);
    }
  };

  const handleAction = (actionType) => {
    setAction(actionType);
    setOpenPopup(true);
    switch (actionType) {
      case "releaseKey": {
        setTextPopup("");
        break;
      }
      case "access": {
        const text = user.status
          ? "Заблокировать пользователя?"
          : "Разблокировать пользователя?";
        setTextPopup(text);
        break;
      }

      default:
        setTextPopup("Подтвердите действие!");
        break;
    }
  };
  const handleCancel = () => {
    setOpenPopup(false);
  };

  const handleConfirmAction = async (value) => {
    if (action === "releaseKey") {
      console.log(value);
      try {
        const result = await releasePersonKey(
          user._id,
          value.inputValue,
          value.selectedServer
        );
          setMessage({code: 200, text: `Ключ ${result.key.name} для пользователя ${user.username} выпущен`});
      } catch (error) {
        setMessage(error);
      }
    } else if (action === "access") {
      try {
        const result = await accessUser(user._id);
        if (result) {
          setUser({ ...user, status: !user.status });
        }
        setMessage({code: 200, text: `Пользователь ${result.status ? "заблокирован" : "разблокирован"}`});
      } catch (error) {
        setMessage(error);
      }
    }
    setOpenPopup(false);
  };

  return (
    <div className="h-full flex flex-col justify-center items-center relative">
      <MessageModal message={message}></MessageModal>
      <h1 className="text-center py-5 text-3xl">
        Параметры пользователя {user.username}
      </h1>
      <div className="min-w-96  w-1/3 flex flex-row justify-between">
        <GenericForm
          columns={userColumns}
          data={user}
          onChange={(key, value) => setUser({ ...user, [key]: value })}
          onSubmit={handleSubmit}
        />
        <div className="w-1/3 m-6">
          <Button
            onClick={() => handleAction("releaseKey")}
            type="primary"
            className="w-full mt-4"
          >
            Выпустить ключ
          </Button>
          <Button
            onClick={() => handleAction("access")}
            type="primary"
            className="w-full mt-4"
          >
            {!user.status ? "Разблокировать" : "Заблокировать"}
          </Button>
        </div>
      </div>
      <Popup
        visible={openPopup}
        onConfirm={handleConfirmAction}
        onCancel={handleCancel}
        text={textPopup}
        action={action}
      />
    </div>
  );
};

export default User;
