import { Table } from "antd";
import { useMemo, useState } from "react";

const TableCustom = ({ items, columns }) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = useMemo(() => {
    if (!searchText) return items;
    return items.filter((item) =>
      Object.keys(item).some(
        (key) =>
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, items]);

  return (
    <div className="w-full z-10">
      <input
        placeholder="Поиск"
        value={searchText}
        onChange={handleSearch}
        className="w-48 h-9 border-b border-gray-300 mb-2 float-end focus:outline-none"
      ></input>
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey="_id" // Указывает, какое поле использовать в качестве уникального ключа строки
        pagination={{ pageSize: 10 }} // Опционально: настройка пагинации
      />
    </div>
  );
};

export default TableCustom;
