import { Form, Input, Select, Button, message, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { getServers } from "../api/respons";

const { Option } = Select;

const InputPeriod = ({ form }) => {
  const [servers, setServers] = useState([]);

  useEffect(() => {
    const fetchServers = async () => {
      try {
        const data = await getServers();
        if (data.servers && data.servers.length > 0) {
          setServers(data.servers);
        }
      } catch (error) {
        console.error("Ошибка при получении серверов:", error);
      }
    };

    fetchServers();
  }, []);

  return (
    <Form form={form} layout="vertical" name="releaseKeyForm">
      <Form.Item
        name="inputValue"
        label="Введите, на какой период выпустить ключ (в часах):"
        rules={[
          { required: true, message: "Пожалуйста, введите значение." },
          {
            validator: (_, value) => {
              if (value < 0) {
                return Promise.reject(
                  new Error("Значение должно быть не меньше 0.")
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input type="number" min={0} placeholder="Введите число" />
      </Form.Item>

      <Form.Item
        name="selectedServer" // Обратите внимание на это имя
        label="Выберите сервер"
        rules={[{ required: true, message: "Пожалуйста, выберите сервер." }]} // Если сервер обязателен
      >
        <Select
          placeholder={`Выберите сервер`}
          onChange={(value) => form.setFieldsValue({ selectedServer: value })}
        >
          {servers.length
            ? servers.map((server) => (
                <Option key={server._id} value={server.name}>
                  {server.name}
                </Option>
              ))
            : null}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default InputPeriod;
