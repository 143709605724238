import TableCustom from "./TableCustom";
import {columnYookassaOrder} from "../config/columnYookassaOrder";
import MessageModal from "./MessageModal";
import {useEffect, useState} from "react";
import {getOrdersYookassa} from "../api/respons";

const OrdersYookassa=() => {

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const data = await getOrdersYookassa();
                if (data.orders && data.orders.length > 0) {
                    setOrders(data.orders);
                }
            } catch (error) {
                console.error("Ошибка при получении серверов:", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);


    return (
        <div className="container mx-auto p-4 te">
            <h1 className="text-center py-5 text-3xl">Список ордеров Yookassa</h1>
            <TableCustom items={orders} columns={columnYookassaOrder} />
            <MessageModal message={error}></MessageModal>
        </div>
    );
}

export default OrdersYookassa;