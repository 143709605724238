import { useParams } from "react-router-dom";
import { getKey, recallKey, extendKey, downSpeed, upSpeed } from "../api/respons";
import { useEffect, useState } from "react";
import { keyColumns } from "../config/column";
import GenericForm from "./GenericForm";
import { Button } from "antd";
import Popup from "./Popup";
import MessageModal from "./MessageModal";
import {Link} from "react-router-dom";
import formatUnixTimestamp from "../utils/formatedUnix";

const Key = () => {
  const [key, setKey] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [action, setAction] = useState(null);
  const [textPopup, setTextPopup] = useState("");
  const { _id } = useParams();

  useEffect(() => {
    const fetchKey = async (_id) => {
      try {
        const data = await getKey(_id);
        if (data.key) {
          setKey(data.key);
        }
      } catch (error) {
        setMessage(error);
      } finally {
        setLoading(false);
      }
    };

    fetchKey(_id);
  }, []);

  const handleSubmit = async (values) => {
    try {
      console.log("Редактирование сервера прошло успешно");
    } catch (error) {
      console.error("Ошибка редактирования сервера: ", error);
    }
  };

  const handleAction = (actionType) => {
    setAction(actionType);
    setOpenPopup(true);
    switch (actionType) {
      case "recall": {
        setTextPopup("Вы уверены, что хотите отозвать ключ?");
        break;
      }
      case "extendKey": {
        setTextPopup("");
        break;
      }
      case "downspeed": {
        setTextPopup(
          "Вы уверены, что хотите заблокировать ключ? В таком случае, пропускная способность канала будет равна 0."
        );
        break;
      }
      case "upspeed": {
        setTextPopup(
          "Вы уверены, что хотите разблокировать ключ. В таком случае, пропускная способность канала будет равна 8 мбит/c"
        );
        break;
      }

      default:
        setTextPopup("");
        break;
    }
  };

  const handleCancelReboot = () => {
    setOpenPopup(false);
  };

  const handleConfirmAction = async (value) => {
    if (action === "recall") {
      try {
        const newKey = await recallKey(_id);
        setKey(newKey);
        setMessage({code: 200, text: "Ключ отозван"});
      } catch (error) {
        setMessage(error)
      }
    }
    if (action === "extendKey") {
      try {
        const data = await extendKey(_id, value);
        setKey(data.key);
      } catch (error) {
        setMessage(error)
      }
    }
    if (action === "downspeed") {
      try {
        const newKey = await downSpeed(_id);
        setKey(newKey);
      } catch (error) {
        setMessage(error)
      }
    }
    if (action === "upspeed") {
      try {
     const newKey = await upSpeed(_id);
        setKey(newKey);
      } catch (error) {
        setMessage(error)
      }
    }
    setOpenPopup(false);
  };
  const CardKey = ({ keyColumns, keyData }) => {
    return (
        <table className="w-full min-w-[385px]">
          <tbody>
          {keyColumns.map((keyColumn) => (
              <tr className="flex gap-3 w-full justify-between" key={keyColumn.key}>
                <td className="mb-2">{keyColumn.title}</td>
                <td>
                  {keyColumn.dataIndex === "status" ? (
                      keyData[keyColumn.key] ? "Доступен" : "Отозван"
                  ) : keyColumn.dataIndex.includes("date") ? (
                      formatUnixTimestamp(keyData[keyColumn.key])
                  ) : (
                      keyData[keyColumn.key]
                  )}
                </td>
              </tr>
          ))}
          </tbody>
        </table>
    );
  };


  return (
    <div className="h-full flex flex-col justify-center items-center relative">

      <h1 className="text-center py-5 text-3xl mb-8">Параметры ключа {key.name}</h1>
      <div className="min-w-48 w-1/3 flex flex-row justify-between">
        <CardKey keyColumns={keyColumns} keyData={key}></CardKey>
{/*        <GenericForm
          columns={keyColumns}
          data={key}
          onChange={(item, value) => setKey({ ...item, [key]: value })}
          onSubmit={handleSubmit}
        />*/}
        <div className="w-1/3 m-6">
          {key.status ? (
            <>
              <Button
                onClick={() => handleAction("recall")}
                type="primary"
                className="w-full mt-4"
              >
                Отозвать ключ
              </Button>{" "}
              <Button
                onClick={() => handleAction("extendKey")}
                type="primary"
                className="w-full mt-4"
              >
                Продлить ключ
              </Button>
              {key.speed > 0.01 ? (
                <Button
                  onClick={() => handleAction("downspeed")}
                  type="primary"
                  className="w-full mt-4"
                >
                  Заблокировать
                </Button>
              ) : (
                <Button
                  onClick={() => handleAction("upspeed")}
                  type="primary"
                  className="w-full mt-4"
                >
                  Разблокировать
                </Button>
              )}
            </>
          ) : null}
        </div>
      </div>
      <Popup
        visible={openPopup}
        onConfirm={handleConfirmAction}
        onCancel={handleCancelReboot}
        text={textPopup}
        action={action} // Передаем функцию для установки файла
      />
      <MessageModal message={message}></MessageModal>
    </div>
  );
};

export default Key;
