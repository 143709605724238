import { Link } from "react-router-dom";
import formatUnixTimestamp from "../utils/formatedUnix";


export const serverColumns = [
  {
    title: "_id",
    dataIndex: "_id",
    key: "_id",
    sorter: (a, b) => a._id.localeCompare(b._id),
    type: "text",
    editable: false,
  },
  {
    title: "Название",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    type: "text",
    editable: true,
    render: (text, record) => (
      <Link className="underline text-sky-500" to={`/servers/${record._id}`}>
        {text}
      </Link>
    ),
    validation: {
      required: true,
      message: "Название обязательно для заполнения",
      min: 3,
      max: 50,
    },
  },
  {
    title: "Адрес",
    dataIndex: "ipv4",
    key: "ipv4",
    type: "text",
    editable: true,
    sorter: (a, b) => a.ipv4.localeCompare(b.ipv4),
    validation: {
      required: true,
      message: "IP-адрес обязателен",
      pattern:
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:(6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|\d{1,4}))?$/,
      messagePattern: "Неверный формат IP-адреса",
    },
  },
  {
    title: "Регион",
    dataIndex: "region",
    key: "region",
    type: "text",
    editable: true,
    sorter: (a, b) => a.region.localeCompare(b.region),
    validation: {
      required: true,
      message: "Регион обязателен",
      min: 2,
      max: 2,
    },
  },
/*  {
    title: "API-ключ",
    dataIndex: "apiKey",
    key: "apiKey",
    type: "text",
    editable: false,
    sorter: (a, b) => a.apiKey.localeCompare(b.apiKey),
  },*/
  {
    title: "Кол-во ключей",
    dataIndex: "totalKeys",
    key: "totalKeys",
    type: "number",
    editable: false,
    sorter: (a, b) => a.totalKeys - b.totalKeys,
  },
  {
    title: "Активные ключи",
    dataIndex: "activeKeys",
    key: "activeKeys",
    type: "number",
    editable: false,
    sorter: (a, b) => a.activeKeys - b.activeKeys,
  },
  {
    title: "Доступно ключей",
    dataIndex: "available",
    key: "available",
    type: "number",
    editable: true,
    sorter: (a, b) => a.available - b.available,
  },
  {
    title: "Сервис",
    dataIndex: "service",
    key: "service",
    type: "text",
    editable: true,
    sorter: (a, b) => a.service.localeCompare(b.service),
    validation: {
      required: true,
      message: "Сервис обязателен",
      min: 2,
      max: 30,
    },
  },
  {
    title: "Канал",
    dataIndex: "throughput",
    key: "throughput",
    type: "number",
    editable: true,
    sorter: (a, b) => a.throughput - b.throughput,
    validation: {
      required: true,
      message: "Канал обязателен",
    },
  },
  /*   {
    title: "_v",
    dataIndex: "__v",
    key: "__v",
    sorter: (a, b) => a.__v - b.__v,
  }, */
];

export const userColumns = [
  {
    title: "_id",
    dataIndex: "_id",
    key: "_id",
    sorter: (a, b) => a._id.localeCompare(b._id),
    type: "text",
    editable: false,
  },
  {
    title: "Юзернейм",
    dataIndex: "username",
    key: "username",
    sorter: (a, b) => a.username.localeCompare(b.username),
    type: "text",
    editable: true,
    render: (text, record) => (
      <Link className="underline text-sky-500" to={`/users/${record._id}`}>
        {text}
      </Link>
    ),
    validation: {
      required: true,
      message: "Юзернейм обязательно для заполнения",
      min: 3,
      max: 50,
    },
  },
  {
    title: "Телеграм ID",
    dataIndex: "telegramId",
    key: "telegramId",
    type: "number",
    editable: false,
    sorter: (a, b) => a.telegramId - b.telegramId,
    validation: {
      required: true,
    },
  },
  {
    title: "Имя",
    dataIndex: "firstName",
    key: "firstName",
    type: "text",
    editable: true,
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    validation: {
      min: 0,
      max: 50,
    },
  },
  {
    title: "Фамилия",
    dataIndex: "lastName",
    key: "lastName",
    type: "text",
    editable: true,
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    validation: {
      min: 0,
      max: 50,
    },
  },
  {
    title: "Регион",
    dataIndex: "region",
    key: "region",
    type: "text",
    editable: true,
    sorter: (a, b) => a.region.localeCompare(b.region),
    validation: {
      required: true,
      message: "Регион обязателен",
      min: 2,
      max: 2,
    },
  },
  {
    title: "Дата регистрации",
    dataIndex: "registrationTime",
    key: "registrationTime",
    type: "text",
    editable: false,
    sorter: (a, b) => a.registrationTime - b.registrationTime,
    render: (text, record) => {
      return formatUnixTimestamp(record.registrationTime);
    },
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    type: "boolean",
    editable: true,
    sorter: (a, b) => Number(b.status) - Number(a.status),
    render: (text, record) => {
      return record.status ? (
        <span className="text-green-500">Доступ открыт</span>
      ) : (
        <span className="text-red-500">Заблокирован</span>
      );
    },
  },
  {
    title: "Баланс RUB",
    dataIndex: "balance.rub",
    key: "balance.rub",
    type: "number",
    editable: true,
    sorter: (a, b) => a.balance.rub - b.balance.rub,
    validation: {
      required: true,
      min: 0,
      message: "Количество обязательно",
    },
    render: (text, record) => {
      return record.balance?.rub || 0;
    },
  },

  {
    title: "Баланс USD",
    dataIndex: "balance.usd",
    key: "balance.usd",
    type: "number",
    editable: true,
    sorter: (a, b) => a.balance.usd - b.balance.usd,
    validation: {
      required: true,
      min: 0,
      message: "Количество обязательно",
    },
    render: (text, record) => {
      return record.balance?.usd || 0;
    },
  },
];

export const keyColumns = [
  {
    title: "_id",
    dataIndex: "_id",
    key: "_id",
    sorter: (a, b) => a._id.localeCompare(b._id),
    type: "text",
    editable: false,
  },
  {
    title: "Название ключа",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    type: "text",
    editable: true,
    render: (text, record) => (
      <Link className="underline text-sky-500" to={`/key/${record._id}`}>
        {text}
      </Link>
    ),
    validation: {
      required: true,
      message: "Название ключа обязательно для заполнения",
      min: 3,
      max: 30,
    },
  },
  {
    title: "Название сервера",
    dataIndex: "server",
    key: "server",
    type: "text",
    editable: true,
    sorter: (a, b) => a.server.localeCompare(b.server),
    validation: {
      required: true,
      message: "Название обязательно для заполнения",
      min: 3,
      max: 50,
    },
  },
  {
    title: "Дата начала",
    dataIndex: "dateStart",
    key: "dateStart",
    type: "text",
    editable: false,
    sorter: (a, b) => a.dateStart - b.dateStart,
    render: (text, record) => {
      return formatUnixTimestamp(record.dateStart);
    },
  },
  {
    title: "IP клиента",
    dataIndex: "ip",
    key: "ip",
    type: "text",
    editable: false,
    sorter: (a, b) => a.ip - b.ip,
  },
  {
    title: "Дата окончания",
    dataIndex: "dateEnd",
    key: "dateEnd",
    type: "text",
    editable: true,
    sorter: (a, b) => a.dateEnd - b.dateEnd,
    render: (text, record) => {
      return formatUnixTimestamp(record.dateEnd);
    },
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    type: "text",
    editable: false,
    sorter: (a, b) => Number(b.status) - Number(a.status),
    render: (text, record) => {
      return record.status ? (
        <span className="text-green-500">Активен</span>
      ) : (
        <span className="text-red-500">Неактивен</span>
      );
    },
  },

  {
    title: "Пользователь",
    dataIndex: "user",
    key: "user",
    type: "number",
    editable: false,
    sorter: (a, b) => a.user - b.user,
    render: (text, record) => <Link to={`/user/${record.user}`}>{text}</Link>,
  },
  {
    title: "Скорость Мбит/сек",
    dataIndex: "speed",
    key: "speed",
    type: "number",
    editable: false,
    sorter: (a, b) => a.speed - b.speed,
  },
];
export const orderColumns = [
  {
    title: "ID Платежа",
    dataIndex: "payment_id",
    key: "payment_id",
    type: "number",
    editable: false,
    sorter: (a, b) => a.payment_id - b.payment_id,
  },
  {
    title: "ID Ордера",
    dataIndex: "order_id",
    key: "order_id",
    sorter: (a, b) => a.order_id - b.order_id,
    type: "number",
    editable: false,
    /*     render: (text, record) => (
      <Link className="underline text-sky-500" to={`/order/${record._id}`}>
        {text}
      </Link>
    ), */
  },
  {
    title: "ID Пользователя",
    dataIndex: "user_id",
    key: "user_id",
    type: "text",
    editable: false,
    sorter: (a, b) => a.user_id - b.user_id,
  },
  {
    title: "Адрес платежа",
    dataIndex: "pay_address",
    key: "pay_address",
    type: "text",
    editable: false,
    sorter: (a, b) => a.pay_address.localeCompare(b.pay_address),
  },
  {
    title: "Сумма в USD",
    dataIndex: "price_amount",
    key: "price_amount",
    type: "number",
    editable: false,
    sorter: (a, b) => a.price_amount - b.price_amount,
  },
  {
    title: "Сумма",
    dataIndex: "pay_amount",
    key: "pay_amount",
    type: "number",
    editable: false,
    sorter: (a, b) => a.pay_amount - b.pay_amount,
  },

  {
    title: "Валюта",
    dataIndex: "pay_currency",
    key: "pay_currency",
    type: "text",
    editable: false,
    sorter: (a, b) => a.pay_currency.localeCompare(b.pay_currency),
  },
  {
    title: "Статус",
    dataIndex: "payment_status",
    key: "payment_status",
    type: "text",
    editable: false,
    sorter: (a, b) => a.payment_status - b.payment_status,
  },
  {
    title: "Время создания",
    dataIndex: "created_at",
    key: "created_at",
    type: "text",
    editable: false,
    sorter: (a, b) => a.created_at - b.created_at,
    render: (text, record) => {
      const unixTimeMilliseconds = new Date(record.created_at).getTime()
      const unixTimeSeconds = Math.floor(unixTimeMilliseconds / 1000)
      return formatUnixTimestamp(unixTimeSeconds);
    },
  },
  {
    title: "Время обновления",
    dataIndex: "valid_until",
    key: "valid_until",
    type: "text",
    editable: false,
    sorter: (a, b) => a.valid_until - b.valid_until,
    render: (text, record) => {
      const unixTimeMilliseconds = new Date(record.valid_until).getTime()
      const unixTimeSeconds = Math.floor(unixTimeMilliseconds / 1000)
      return formatUnixTimestamp(unixTimeSeconds);
    },
  },
];
export const configColumns = [
  {
    title: "_id",
    dataIndex: "_id",
    key: "_id",
    type: "text",
    editable: false,
    sorter: (a, b) => a._id - b._id,
  },
  {
    title: "Мин. Сумма пополнения USD",
    dataIndex: "amount.minAmount",
    key: "minAmount",
    type: "number",
    editable: true,
    sorter: (a, b) => a.minAmount - b.minAmount,
    validation: {
      required: true,
      message: "Обязательное поле для редактирования",
      min: 0,
      max: 999999,
    },
  },
  {
    title: "Макс. Сумма пополнения USD",
    dataIndex: "amount.maxAmount",
    key: "maxAmount",
    type: "number",
    editable: true,
    sorter: (a, b) => a.maxAmount - b.maxAmount,
    validation: {
      required: true,
      message: "Обязательное поле для редактирования",
      min: 0,
      max: 999999,
    },
  },
  {
    title: "Тариф RUB (1 мес)",
    dataIndex: "rates.rub.one.price",
    key: "rateRubOneMonthPrice",
    type: "number",
    editable: true,
    sorter: (a, b) => a.rates.rub.one.price - b.rates.rub.one.price,
    render: (text, record) => record.rates.rub.one.price || 0,
    validation: {
      required: true,
      message: "Цена обязательна для редактирования",
      min: 0,
      max: 999999,
    },
  },
  {
    title: "Количество месяцев RUB (1 мес)",
    dataIndex: "rates.rub.one.month",
    key: "rateRubOneMonth",
    type: "number",
    editable: false,
    sorter: (a, b) => a.rates.rub.one.month - b.rates.rub.one.month,
    render: (text, record) => record.rates.rub.one.month || 1,
    validation: {
      required: true,
      message: "Количество месяцев обязательно для редактирования",
      min: 1,
      max: 12,
    },
  },
  {
    title: "Тариф RUB (3 мес)",
    dataIndex: "rates.rub.two.price",
    key: "rateRubThreeMonthPrice",
    type: "number",
    editable: true,
    sorter: (a, b) => a.rates.rub.two.price - b.rates.rub.two.price,
    render: (text, record) => record.rates.rub.two.price || 0,
    validation: {
      required: true,
      message: "Цена обязательна для редактирования",
      min: 0,
      max: 999999,
    },
  },
  {
    title: "Количество месяцев RUB (3 мес)",
    dataIndex: "rates.rub.two.month",
    key: "rateRubThreeMonth",
    type: "number",
    editable: false,
    sorter: (a, b) => a.rates.rub.two.month - b.rates.rub.two.month,
    render: (text, record) => record.rates.rub.two.month || 3,
    validation: {
      required: true,
      message: "Количество месяцев обязательно для редактирования",
      min: 1,
      max: 12,
    },
  },
  {
    title: "Тариф RUB (6 мес)",
    dataIndex: "rates.rub.three.price",
    key: "rateRubSixMonthPrice",
    type: "number",
    editable: true,
    sorter: (a, b) => a.rates.rub.three.price - b.rates.rub.three.price,
    render: (text, record) => record.rates.rub.three.price || 0,
    validation: {
      required: true,
      message: "Цена обязательна для редактирования",
      min: 0,
      max: 999999,
    },
  },
  {
    title: "Количество месяцев RUB (6 мес)",
    dataIndex: "rates.rub.three.month",
    key: "rateRubSixMonth",
    type: "number",
    editable: false,
    sorter: (a, b) => a.rates.rub.three.month - b.rates.rub.three.month,
    render: (text, record) => record.rates.rub.three.month || 6,
    validation: {
      required: true,
      message: "Количество месяцев обязательно для редактирования",
      min: 1,
      max: 12,
    },
  },
  {
    title: "Тариф RUB (12 мес)",
    dataIndex: "rates.rub.four.price",
    key: "rateRubTwelveMonthPrice",
    type: "number",
    editable: true,
    sorter: (a, b) => a.rates.rub.four.price - b.rates.rub.four.price,
    render: (text, record) => record.rates.rub.four.price || 0,
    validation: {
      required: true,
      message: "Цена обязательна для редактирования",
      min: 0,
      max: 999999,
    },
  },
  {
    title: "Количество месяцев RUB (12 мес)",
    dataIndex: "rates.rub.four.month",
    key: "rateRubFourMonth",
    type: "number",
    editable: false,
    sorter: (a, b) => a.rates.rub.four.month - b.rates.rub.four.month,
    render: (text, record) => record.rates.rub.four.month || 6,
    validation: {
      required: true,
      message: "Количество месяцев обязательно для редактирования",
      min: 1,
      max: 12,
    }
  },
  {
    title: "Тариф USD (1 мес)",
    dataIndex: "rates.usd.one.price",
    key: "rateUsdOneMonthPrice",
    type: "number",
    editable: true,
    sorter: (a, b) => a.rates.usd.one.price - b.rates.usd.one.price,
    render: (text, record) => record.rates.usd.one.price || 0,
    validation: {
      required: true,
      message: "Цена обязательна для редактирования",
      min: 0,
      max: 999999,
    },
  },
  {
    title: "Количество месяцев USD (1 мес)",
    dataIndex: "rates.usd.one.month",
    key: "rateUsdOneMonth",
    type: "number",
    editable: false,
    sorter: (a, b) => a.rates.usd.one.month - b.rates.usd.one.month,
    render: (text, record) => record.rates.usd.one.month || 1,
    validation: {
      required: true,
      message: "Количество месяцев обязательно для редактирования",
      min: 1,
      max: 12,
    },
  },
  {
    title: "Тариф USD (3 мес)",
    dataIndex: "rates.usd.two.price",
    key: "rateUsdThreeMonthPrice",
    type: "number",
    editable: true,
    sorter: (a, b) => a.rates.usd.two.price - b.rates.usd.two.price,
    render: (text, record) => record.rates.usd.two.price || 0,
    validation: {
      required: true,
      message: "Цена обязательна для редактирования",
      min: 0,
      max: 999999,
    },
  },
  {
    title: "Количество месяцев USD (3 мес)",
    dataIndex: "rates.usd.two.month",
    key: "rateUsdThreeMonth",
    type: "number",
    editable: false,
    sorter: (a, b) => a.rates.usd.two.month - b.rates.usd.two.month,
    render: (text, record) => record.rates.usd.two.month || 3,
    validation: {
      required: true,
      message: "Количество месяцев обязательно для редактирования",
      min: 1,
      max: 12,
    },
  },
  {
    title: "Тариф USD (6 мес)",
    dataIndex: "rates.usd.three.price",
    key: "rateUsdSixMonthPrice",
    type: "number",
    editable: true,
    sorter: (a, b) => a.rates.usd.three.price - b.rates.usd.three.price,
    render: (text, record) => record.rates.usd.three.price || 0,
    validation: {
      required: true,
      message: "Цена обязательна для редактирования",
      min: 0,
      max: 999999,
    },
  },
  {
    title: "Количество месяцев USD (6 мес)",
    dataIndex: "rates.usd.three.month",
    key: "rateUsdSixMonth",
    type: "number",
    editable: false,
    sorter: (a, b) => a.rates.usd.three.month - b.rates.usd.three.month,
    render: (text, record) => record.rates.usd.three.month || 6,
    validation: {
      required: true,
      message: "Количество месяцев обязательно для редактирования",
      min: 1,
      max: 12,
    },
  },
  {
    title: "Тариф USD (12 мес)",
    dataIndex: "rates.usd.four.price",
    key: "rateUsdTwelveMonthPrice",
    type: "number",
    editable: true,
    sorter: (a, b) => a.rates.usd.four.price - b.rates.usd.four.price,
    render: (text, record) => record.rates.usd.four.price || 0,
    validation: {
      required: true,
      message: "Цена обязательна для редактирования",
      min: 0,
      max: 999999,
    },
  },
  {
    title: "Количество месяцев USD (12 мес)",
    dataIndex: "rates.usd.four.month",
    key: "rateUsdSixMonth",
    type: "number",
    editable: false,
    sorter: (a, b) => a.rates.usd.four.month - b.rates.usd.four.month,
    render: (text, record) => record.rates.usd.four.month || 0,
    validation: {
      required: true,
      message: "Количество месяцев обязательно для редактирования",
      min: 1,
      max: 12,
    },
  },
  {
    title: "Криптовалюты",
    dataIndex: "cryptocurrency",
    key: "cryptocurrency",
    type: "array",
    editable: true,
  },
  {
    title: "Валюты",
    dataIndex: "currency",
    key: "currency",
    type: "array",
    editable: true,
  },
  {
    title: "Допустимые регионы серверов",
    dataIndex: "codesToMatch",
    key: "codesToMatch",
    type: "array",
    editable: true,
  },
  {
    title: "Прием банковских платежей",
    dataIndex: "banks",
    key: "banks",
    type: "boolean",
    editable: true,
  },
  {
    title: "Текущая платежная система",
    dataIndex: "currentPaymentSystem",
    key: "currentPaymentSystem",
    type: "text",
    editable: true,
    options: "paymentSystem"
  },
  {
    title: "Платежные системы",
    dataIndex: "paymentSystem",
    key: "paymentSystem",
    type: "array",
    editable: true,
  },
  {
    title: "Скорость клиента",
    dataIndex: "speedClient",
    key: "speedClient",
    type: "number",
    editable: true,
    sorter: (a, b) => a.speedClient - b.speedClient,
    validation: {
      required: true,
      message: "Обязательное поле для редактирования",
      min: 8,
      max: 1000,
    },
  },
  {
    title: "Скорость клиента Мин.",
    dataIndex: "speedDown",
    key: "speedDown",
    type: "number",
    editable: true,
    sorter: (a, b) => a.speedDown - b.speedDown,
    validation: {
      required: true,
      message: "Обязательное поле для редактирования",
      min: 0,
      max: 1000,
    },
  },
  {
    title: "Начальный ID ордера",
    dataIndex: "orders.beginOrderID",
    key: "orders.beginOrderID",
    type: "number",
    editable: true,
    sorter: (a, b) => a.beginOrderID - b.beginOrderID,
    validation: {
      required: true,
      message: "Обязательное поле для редактирования",
      min: 10000,
      max: 99999999,
    },
    render: (text, record) => record.orders.beginOrderID,
  },
  {
    title: "Последний ID ордера",
    dataIndex: "orders.lastOrderID",
    key: "orders.lastOrderID",
    type: "number",
    editable: false,
    sorter: (a, b) => a.lastOrderID - b.lastOrderID,
    validation: {
      required: true,
      message: "Обязательное поле для редактирования",
      min: 10000,
      max: 99999999,
    },
    render: (text, record) => record.orders.lastOrderID,
  },
  {
    title: "Черный список регионов",
    dataIndex: "blackCountryList",
    key: "blackCountryList",
    type: "array",
    editable: true,
    sorter: (a, b) => a.blackCountryList - b.blackCountryList,
  },
  {
    title: "Username поддержки",
    dataIndex: "supportUserName",
    key: "supportUserName",
    type: "text",
    editable: true,
    sorter: (a, b) => a.supportUserName - b.supportUserName,
    validation: {
      required: true,
      message: "Обязательное поле для редактирования",
      min: 3,
      max: 50,
    },
  },
];

export const paymentColumn = [
  {
    title: "_id",
    dataIndex: "_id",
    key: "_id",
    type: "text",
    editable: false,
    sorter: (a, b) => a._id - b._id,
  },
  {
    title: "Сумма",
    dataIndex: "amount",
    key: "amount",
    type: "number",
    editable: false,
    sorter: (a, b) => a.amount - b.amount,
  },
  {
    title: "Валюта",
    dataIndex: "currency",
    key: "currency",
    type: "text",
    editable: false,
    sorter: (a, b) => a.currency - b.currency,
  },
  {
    title: "Пользователь",
    dataIndex: "user",
    key: "user",
    type: "text",
    editable: false,
    sorter: (a, b) => a.user - b.user,
  },
  {
    title: "Тип платежа",
    dataIndex: "type",
    key: "type",
    type: "text",
    editable: false,
    sorter: (a, b) => a.type - b.type,
    render: (text, record) => {
      if (record.type === "buy")  {
        return "Покупка"
      }
      if (record.type === "renew")  {
        return "Продление"
      }
    },
  },
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
    type: "text",
    editable: false,
    sorter: (a, b) => a.date - b.date,
    render: (text, record) => {
      const unixTime = Math.floor(new Date(record.date).getTime() / 1000);
      return formatUnixTimestamp(unixTime)
    }
  }
];