import { keyColumns } from "../config/column";
import { getKeys } from "../api/respons";
import { useEffect, useState } from "react";
import TableCustom from "./TableCustom";

const Keys = () => {
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchKeys = async () => {
      try {
        const data = await getKeys();
        if (data.keys && data.keys.length > 0) {
          setKeys(data.keys);
        }
      } catch (error) {
        console.error("Ошибка при получении серверов:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchKeys();
  }, []);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>Произошла ошибка: {error.message}</div>;
  }

  return (
    <div className="container mx-auto p-4 te">
      <h1 className="text-center py-5 text-3xl">Список ключей</h1>
      <TableCustom items={keys} columns={keyColumns} />
    </div>
  );
};

export default Keys;
