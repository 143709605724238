import { Form, Input, Button } from "antd";
import { useState } from "react";
import {domainCheck, domainAdd, domainRem} from "../api/respons"
import MessageModal from "./MessageModal";

const DomainControl = () => {
    const [domain, setDomain] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [check, setCheck] = useState(null);

    const handleInputChange = (e) => {
        setDomain(e.target.value);
        setError(""); // Сбрасываем ошибку при изменении ввода
    };

    const handleDomainAdd = async() => {
        let sanitizedDomain = domain.replace(/^(https?:\/\/)?(www\.)?/, "").replace(/\/$/, "");
        const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/;

        if (domainPattern.test(sanitizedDomain)) {
            try {
                const res = await domainAdd(domain)

                setCheck(res.message === "OK" ? domain : "")
            } catch (e) {
                setMessage(e);
            }
            setError(""); // Сбрасываем ошибку, если домен валиден
        } else {
            setError("Неверный формат домена"); // Устанавливаем сообщение об ошибке
        }
    }

    const handleDomainRem = async() => {
        let sanitizedDomain = domain.replace(/^(https?:\/\/)?(www\.)?/, "").replace(/\/$/, "");
        const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/;

        if (domainPattern.test(sanitizedDomain)) {
            try {
                const res = await domainRem(domain)

                setCheck(res.message === "OK" ? "" : domain)
            } catch (e) {
                setMessage(e);
            }
            setError(""); // Сбрасываем ошибку, если домен валиден
        } else {
            setError("Неверный формат домена"); // Устанавливаем сообщение об ошибке
        }
    }

    const handleDomainCheck = async() => {
        let sanitizedDomain = domain.replace(/^(https?:\/\/)?(www\.)?/, "").replace(/\/$/, "");
        const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/;

        if (domainPattern.test(sanitizedDomain)) {
            try {
              const res = await  domainCheck(domain)
                console.log(res)
                setCheck(res.domain ? res.domain : "")
            } catch (e) {
                setMessage(e);
            }
            setError(""); // Сбрасываем ошибку, если домен валиден
        } else {
            setError("Неверный формат домена"); // Устанавливаем сообщение об ошибке
        }
    };

    return (
        <section className="flex flex-col items-center">
            <MessageModal message={message}></MessageModal>
            <h1 className="text-center py-5 text-3xl">Управление доменами</h1>
            <Form className="w-1/3 flex flex-row gap-1" onFinish={handleDomainCheck}>
                <Form.Item
                    validateStatus={error ? "error" : ""}
                    help={error}
                    className="w-full"
                >
                    <Input
                        placeholder="example.com"
                        value={domain}
                        onChange={handleInputChange}
                    />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    Проверить домен
                </Button>
            </Form>
            <h2 className="text-sm p-0 m-0">Домен в списке {check === null ? null : (
                check === ""
                    ? <span className='text-red-500'>отсутствует. <Button type="link" onClick={handleDomainAdd} className="p-0">Добавить?</Button></span>
                    : <span className='text-green-500'>присутствует. <Button type="link" onClick={handleDomainRem} className="p-0">Удалить?</Button></span>
            )}</h2>
        </section>
);
};

export default DomainControl;
