import { useEffect, useState} from "react";
import {getToken} from "../api/respons"
import {useNavigate} from "react-router-dom";
import formatUnixTimestamp from "../utils/formatedUnix";
import { Button } from "antd";

const Token = () => {
    const [token, setToken] = useState("");
    const [time, setTime] = useState(0);
    const [formatTime, setFormatTime] = useState("");
    const navigate = useNavigate();

    const fetchToken = async () => {
        try {
            const data = await getToken();
            setToken(data.token);
            setTime(data.time);
            setFormatTime(formatUnixTimestamp(data.time)); // Используем data.time
        } catch (error) {
            if (error.text === "INVALID_TOKEN" || error.text === "AUTHORIZATION_REQUIRED") {
                console.log("Неправильный токен или требуется авторизация");
                navigate("/login");
            } else {
                console.error(error); // Лучше использовать console.error для ошибок
            }
        }
    };

    useEffect(() => {


        fetchToken();
    }, []); // Добавляем navigate в зависимости

    return (
        <div className="h-full flex flex-col justify-center items-center relative">
            <h1 className="text-center py-5 text-3xl">{`Временный токен. Действует до ${formatTime}`}</h1>
            <p className="m-3">token: {token}</p>
            <p className="m-3">time: {time}</p>
            <Button onClick={fetchToken}>Генерация</Button>
        </div>
    );
};

export default Token;