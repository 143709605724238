import { Link } from "react-router-dom";

const ModalNav = ({ state, links }) => {
  const display = state ? "opacity-100" : "opacity-0";
  return (
    <div
      className={`${display} absolute flex flex-col w-[180px] pt-1 gap-1 bg-white left-1/2 transform -translate-x-1/2 rounded-sm p-4 shadow-md z-50`}
    >
      {links.map((link) => (
        <Link
          className="font-light text-[#7A8699] hover:text-black transition-all text-[14px]"
          to={link.link}
        >
          {link.title}
        </Link>
      ))}
    </div>
  );
};
export default ModalNav;
