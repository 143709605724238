import navJson from "../config/nav.json";
import NavLink from "./NavLink";

const Header = () => {
  return (
    <header className="p-8 border-solid border-b-2 flex justify-between items-center">
      <h1 className="text-[#1F2633] text-3xl font-medium">Панель управления</h1>
      <nav className="flex gap-8">
        {navJson.map((link) => (
          <NavLink link={link} />
        ))}
      </nav>
      <a className="transition-all hover:text-[#7A8699] font-medium   cursor-pointer ">
        Выйти
      </a>
    </header>
  );
};
export default Header;
